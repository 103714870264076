<template>
    <Panel header="Salesman Calculation">
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Working Hours per Day</label> 
                    <InputNumber v-model="forms.working_hours" :min="0" mode="decimal" :useGrouping="false" suffix=" hours" :class="{ 'p-invalid': errorAdd.working_hours }"/>
                    <small class="p-invalid" style="color: red" v-if="errorAdd.working_hours" >{{ errorAdd.working_hours[0] }}</small >
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Calculate" icon="pi pi-bolt" class="p-button-primary" @click="calculate" />
        <Button label="Clear" icon="pi pi-refresh" class="p-button-warning ml-2" @click="clearForms" />
    </Panel>
    <BlockUI :blocked="loading">
        <div class="card mt-2" style="margin-bottom: 2em">
            <div class="font-semibold text-xl">Based on the given data:</div>
            <table class="mt-2 text-lg">
                <thead>
                    <tr>
                        <th>Total Outlets</th>
                        <th>Total Call</th>
                        <th>Total Hours</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{items?.total_outlets || 0}}</td>
                        <td>{{items?.total_call || 0}}</td>
                        <td>{{items?.total_hours || 0}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-xl mt-3">The calculation for determining the required number of salespersons is <span class="font-bold underline">{{items?.number_salesman || 0}}</span></div>
        </div>
    </BlockUI>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    data() {
        return {
            // loading
            loading: false,

            // addNew
            forms: {
                working_hours: 0,
            },

            items: null,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    created() {
        this.project_id = this.$route.params.id;
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // ADDNEW
        calculate() {
            this.loading = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/salesman/calculation',
                params: {
                    "project_id": this.project_id,
                    "working_hours": this.forms.working_hours,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});

                    this.items = res.data.data;
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed', life: 3000,});
            });
        },
        clearForms() {
            this.forms.working_hours = 0;
            this.items = null;
        },
    }
}
</script>

<style>
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid #ddd;
        padding: 8px;
    }
    th {
        background-color: #f2f2f2;
        text-align: left;
    }
</style>